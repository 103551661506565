import React from 'react';
import PropTypes from 'prop-types';
import values from 'lodash/values';
import get from 'lodash/get';
import { ParentSpaceProvider, SubspaceProvider } from 'react-redux-subspace';
import MainTabs from '@skunexus/fulfillment-main-tabs';

import app from '../../../../config';
import UserModel from '../../../../UserModel';
import { filterAccessibleItems } from '../../../../utils/helpers';
import { getDefaultAssignUserValue } from '../utils';

function MainTabsLayout({ children, name, ...props }) {
    const mainTabsParamsConfig = get(app.general.getItem('modules'), 'fulfillment.mainTabsParamsConfig', {});
    const rolesForDefaultAssignedUser = get(
        app.general.getItem('modules'),
        'fulfillment.rolesForDefaultAssignedUser',
        [],
    );

    const states = () => {
        const pickupFulfillmentTabs = app.pickupFulfillmentTabs.getAll();

        if (pickupFulfillmentTabs.pick) {
            pickupFulfillmentTabs.pick.config = {
                countable: true,
                countGraphqlFilterPart: 'current_state: {operator: in, value: ["pick", "pick_in_progress"]}',
            };
        }

        if (pickupFulfillmentTabs.pack) {
            pickupFulfillmentTabs.pack.config = {
                countable: true,
                countGraphqlFilterPart: 'current_state: {operator: eq, value: "pack"}',
            };
        }

        if (pickupFulfillmentTabs.groupPick) {
            pickupFulfillmentTabs.groupPick.config = {
                countable: true,
                countGraphqlFilterPart:
                    'type: { operator: like, value: "%manual%" }, state: { operator: neq, value: "picked" }',
                gridField: 'groupGrid',
            };
        }

        if (pickupFulfillmentTabs.waves) {
            pickupFulfillmentTabs.waves.config = {
                countable: true,
                countGraphqlFilterPart:
                    'type: { operator: like, value: "%wave%" }, state: { operator: neq, value: "picked" }',
                gridField: 'groupGrid',
            };
        }
        return values(app.pickupFulfillmentTabs.getAll());
    };

    return (
        <div>
            <ParentSpaceProvider>
                <SubspaceProvider
                    mapState={(state) => ({
                        ...state.fulfillmentMainTabs,
                        ...state[name],
                    })}
                >
                    <>
                        <MainTabs
                            {...props}
                            currentUserId={UserModel.getUserId()}
                            tabsProps={{
                                domain: 'inStoreFulfillment',
                                tabs: filterAccessibleItems(states),
                                ...mainTabsParamsConfig?.tabsProps,
                                ...props?.tabsProps,
                            }}
                            filterProps={{
                                ...mainTabsParamsConfig?.filterProps,
                                ...props?.filterProps,
                                assignUserFilterConfig: {
                                    defaultValue: getDefaultAssignUserValue({
                                        allowedRoles: rolesForDefaultAssignedUser,
                                    }),
                                    ...mainTabsParamsConfig?.filterProps?.assignUserFilterConfig,
                                    ...props?.filterProps?.assignUserFilterConfig,
                                },
                                warehouseFilterConfig: {
                                    ...mainTabsParamsConfig?.filterProps?.warehouseFilterConfig,
                                    ...props?.filterProps?.warehouseFilterConfig,
                                },
                                cartFilterConfig: {
                                    ...mainTabsParamsConfig?.filterProps?.cartFilterConfig,
                                    ...props?.filterProps?.cartFilterConfig,
                                },
                            }}
                        />
                        {children}
                    </>
                </SubspaceProvider>
            </ParentSpaceProvider>
        </div>
    );
}

MainTabsLayout.propTypes = {
    children: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
};

export default MainTabsLayout;
