import { createMainLayout } from '../utils/helpers';
import app from '../config';
import { keys, values } from 'lodash';

const getPreparedItems = (items) =>
    keys(items)
        .map((itemCode) => ({ ...items[itemCode], code: itemCode }))
        .sort(({ order: orderA = 0 }, { order: orderB = 0 }) => orderA - orderB);

const mainLayoutProps = () => ({
    headerMenuItems: getPreparedItems(app.headerMenuItems.getAll()),
    sideMenuItems: getPreparedItems(app.sideMenuItems.getAll()),
});

export default createMainLayout(mainLayoutProps);
