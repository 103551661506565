import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ParentSpaceProvider, SubspaceProvider } from 'react-redux-subspace';
import values from 'lodash/values';
import get from 'lodash/get';
import MainTabs from '@skunexus/fulfillment-main-tabs';

import app from '../../../../config';
import UserModel from '../../../../UserModel';
import { filterAccessibleItems } from '../../../../utils/helpers';

function MainTabsLayout({ children, name, ...props }) {
    const mainTabsParamsConfig = get(app.general.getItem('modules'), 'fulfillment.mainTabsParamsConfig', {});

    const states = () => {
        const tabs = app.receiveInventoryTabs.getAll();

        if (tabs.scanned) {
            tabs.scanned.config = {
                countable: true,
                countGraphqlFilterPart: 'current_state: {operator: eq, value: "ready"}',
            };
        }
        /* eslint-disable no-useless-escape */
        if (tabs.itemsReceived) {
            tabs.itemsReceived.config = {
                countable: true,
                customQuery: `
                    stock {
                        data: stocksGrid(filter: {
                            qty: {operator: neq, value: 0}
                            location: {description: {operator: eq, value: \"{\\\"_receiving_\\\":\\\"_receiving_\\\"}\"}}
                            relatedCart:{id:{operator: notnull value: ""}}
                        }) {
                            totalSize
                        }
                    }
                `,
            };
        }
        /* eslint-enable no-useless-escape */

        return values(tabs);
    };
    return (
        <div>
            <ParentSpaceProvider>
                <SubspaceProvider
                    mapState={(state) => ({
                        ...state.fulfillmentMainTabs,
                        ...state[name],
                    })}
                >
                    <>
                        <MainTabs
                            {...props}
                            currentUserId={UserModel.getUserId()}
                            tabsProps={{
                                domain: 'putAwayFulfillment',
                                tabs: filterAccessibleItems(states),
                                ...mainTabsParamsConfig?.tabsProps,
                                ...props?.tabsProps,
                            }}
                            filterProps={{
                                ...mainTabsParamsConfig?.filterProps,
                                ...props?.filterProps,
                                assignUserFilterConfig: {
                                    ...mainTabsParamsConfig?.filterProps?.assignUserFilterConfig,
                                    ...props?.filterProps?.assignUserFilterConfig,
                                },
                                warehouseFilterConfig: {
                                    ...mainTabsParamsConfig?.filterProps?.warehouseFilterConfig,
                                    ...props?.filterProps?.warehouseFilterConfig,
                                },
                                cartFilterConfig: {
                                    ...mainTabsParamsConfig?.filterProps?.cartFilterConfig,
                                    ...props?.filterProps?.cartFilterConfig,
                                },
                            }}
                        />
                        {children}
                    </>
                </SubspaceProvider>
            </ParentSpaceProvider>
        </div>
    );
}

MainTabsLayout.propTypes = {
    children: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
};

export default MainTabsLayout;
