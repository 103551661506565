import {
    TITLE_PREFIX,
    ORDERS_INDEX_ROUTE,
    ORDERS_FORM_PATH,
    ORDERS_CREATE_FORM_ROUTE,
    ORDERS_DETAILS_PATH,
    ORDERS_DETAILS_ROUTE,
    BACK_ORDERS_ROUTE,
    BACK_ORDERS_TITLE,
} from './consts';
import {
    SHIP_DETAILS_ROUTE,
    SHIP_GROUP_PICK_DETAILS_ROUTE,
    PICKUP_DETAILS_ROUTE,
    PICKUP_GROUP_PICK_DETAILS_ROUTE,
    CANCELLATION_DETAILS_ROUTE,
} from '../fulfillment/consts';
import { VENDOR_DETAILS_ROUTE } from '../vendors/consts';
import { PRODUCTS_DETAILS_ROUTE } from '../products/consts';
import { SHIPMENT_DETAILS_ROUTE } from '../shipment/consts';
import { PO_DETAILS_ROUTE } from '../purchaseOrders/consts';
import { EMPTY_TITLE_CRUMB } from '../constants';

import { useConfigContext } from '../../../contexts/ConfigContext';
import { withDetailsRenderWrapper } from '../../../HOCs/DetailsRenderWrapper';
import { hasAnyPermissions } from '../../../utils/helpers';

export default (app) => {
    const routers = {
        orders: {
            exact: true,
            path: ORDERS_INDEX_ROUTE,
            title: `${TITLE_PREFIX}s`,
            permission: ['orders.index'],
            breadcrumbConfig: { title: `${TITLE_PREFIX}s`, clearPath: true },
            loader: () => import('@skunexus/orders'),
            props: () => ({
                detailsPageRoute: ORDERS_DETAILS_ROUTE,
                addFormPageRoute: ORDERS_CREATE_FORM_ROUTE,
                permissions: {
                    canAdd: hasAnyPermissions('orders.create'),
                    canAutoDecide: hasAnyPermissions('orders.auto decide'),
                    withExport: hasAnyPermissions('orders.export'),
                    canEditCustomAttributes: hasAnyPermissions('orders - custom attributes.value edit'),
                    canShow: hasAnyPermissions('orders.show'),
                    withOrderLink: hasAnyPermissions('orders.show'),
                },
            }),
        },
        orderForm: {
            exact: true,
            path: ORDERS_FORM_PATH,
            title: `${TITLE_PREFIX} Form`,
            permission: ['orders.create'],
            breadcrumbConfig: { title: 'Create', isReplaceble: true },
            props: () => ({
                indexPageRoute: ORDERS_INDEX_ROUTE,
                detailsPageRoute: ORDERS_DETAILS_ROUTE,
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                permissions: {
                    canEditCustomAttributes: hasAnyPermissions('orders - custom attributes.value edit'),
                    withProductLink: hasAnyPermissions('products.show'),
                },
            }),
            loader: () => import('@skunexus/order-form'),
            render: (Component) =>
                function (props) {
                    const [{ setting }] = useConfigContext();
                    const { isKittingEnabled } = setting;

                    return <Component {...props} {...{ isKittingEnabled }} />;
                },
        },
        orderDetails: {
            exact: true,
            path: ORDERS_DETAILS_PATH,
            title: `${TITLE_PREFIX} Details`,
            permission: ['orders.show'],
            breadcrumbConfig: { title: EMPTY_TITLE_CRUMB, isReplaceble: true },
            props: () => ({
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                shipmentDetailsPageRoute: SHIPMENT_DETAILS_ROUTE,
                fulfillmentDetailsPageRouteShip: SHIP_DETAILS_ROUTE,
                fulfillmentDetailsPageRoutePickup: PICKUP_DETAILS_ROUTE,
                fulfillmentGroupDetailsPageRouteShip: SHIP_GROUP_PICK_DETAILS_ROUTE,
                fulfillmentGroupDetailsPageRoutePickup: PICKUP_GROUP_PICK_DETAILS_ROUTE,
                fulfillmentDetailsPageRouteCancellation: CANCELLATION_DETAILS_ROUTE,
                vendorDetailsPageRoute: VENDOR_DETAILS_ROUTE,
                purchaseOrderRoute: PO_DETAILS_ROUTE,
                permissions: {
                    canAutoDecide: hasAnyPermissions('orders.auto decide'),
                    canDecide: hasAnyPermissions('orders.decide'),
                    canCancel: hasAnyPermissions('orders.transition'),
                    canReship: hasAnyPermissions('fulfillments.reship'),
                    canEditOrderAttributes: hasAnyPermissions('orders - custom attributes.value edit'),
                    canEditShippingAddress: hasAnyPermissions('orders.update shipping address'),
                    canEditBillingAddress: hasAnyPermissions('orders.update billing address'),
                    withProductLink: hasAnyPermissions('products.show'),
                    withFulfillmentLink: hasAnyPermissions('fulfillments.show'),
                    withPOLink: hasAnyPermissions('purchase orders.show'),
                },
            }),
            loader: () => import('@skunexus/order-details'),
            render: (Component) =>
                withDetailsRenderWrapper()(function (props) {
                    const [{ setting }] = useConfigContext();
                    const { isKittingEnabled, isForceEntireKittingEnabled } = setting;

                    return <Component {...props} {...{ isKittingEnabled, isForceEntireKittingEnabled }} />;
                }),
        },
        backOrders: {
            path: BACK_ORDERS_ROUTE,
            title: BACK_ORDERS_TITLE,
            permission: ['orders.index'],
            props: () => ({
                permissions: {
                    withOrderLink: hasAnyPermissions('orders.show'),
                    withProductLink: hasAnyPermissions('products.show'),
                },
                routes: {
                    orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                    productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                },
            }),
            loader: () => import('@skunexus/back-order-grid'),
            render: (Component) =>
                function (props) {
                    const [{ setting }] = useConfigContext();
                    const { isBackOrderEnabled = false } = setting;

                    if (!isBackOrderEnabled) {
                        return null;
                    }

                    return <Component {...props} />;
                },
        },
    };

    return routers;
};
