import React from 'react';
import omit from 'lodash/omit';
import get from 'lodash/get';
import has from 'lodash/has';
import findIndex from 'lodash/findIndex';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';

import {
    SHIP_TITLE_PREFIX,
    SHIP_DOMAIN_NAME,
    SHIP_WORKFLOW_NAME,
    SHIP_BATCHING_ROUTE,
    SHIP_BATCH_PRINT_ROUTE,
    SHIP_WAVES_ROUTE,
    SHIP_GROUP_PICK_ROUTE,
    SHIP_DASHBOARD_ROUTE,
    SHIP_CANCEL_ROUTE,
    SHIP_PACK_ROUTE,
    SHIP_PICK_ROUTE,
    SHIP_PRINT_LOGS_ROUTE,
    SHIP_PRINTED_ROUTE,
    SHIP_DETAILS_ROUTE,
    SHIP_GROUP_PICK_DETAILS_ROUTE,
    SHIP_PICKING_ROUTE,
    SHIP_GROUP_PICKING_ROUTE,
    SHIP_PACKING_ROUTE,
    SHIP_PRINT_ITEMS_ROUTE,
    SHIP_GROUP_PRINT_ITEMS_ROUTE,
} from './consts';

import { ORDERS_DETAILS_ROUTE } from '../orders/consts';
import { PRODUCTS_DETAILS_ROUTE } from '../products/consts';
import { USERS_DETAILS_ROUTE } from '../users/consts';
import { SHIPMENT_DETAILS_ROUTE, SHIPMENT_PRINT_ROUTE } from '../shipment/consts';
import { EMPTY_TITLE_CRUMB } from '../constants';

import { useConfigContext } from '../../../contexts/ConfigContext';
import UserModel from '../../../UserModel';
import MainTabs from './components/FulfillmentMainTabsLayout';
import withSettingsTabsWrapper from '../settings/components/withSettingsTabsWrapper';
import { hasAnyPermissions } from '../../../utils/helpers';

export default (app) => {
    const customWaveGridConfig = (data) => {
        if (!has(data.config.filtering, 'state')) {
            set(data, 'config.filtering.state', { operator: 'in', value: ['not_picked', 'partial_picked'] });
        }
        return {
            ...data,
        };
    };

    const customWaveHistoryGridData = (data) => {
        const stateColIndex = findIndex(get(data, 'columns', []), ['field', 'state']);

        if (stateColIndex >= 0) {
            set(data, `columns[${stateColIndex}].isFilterable`, false);
            set(data, `columns[${stateColIndex}].defaultFilter`, ['picked']);
            set(data, `columns[${stateColIndex}].options`, [{ label: 'Picked', value: 'picked' }]);
        }

        return {
            ...data,
        };
    };

    const customWaveHistoryGridConfig = (data) => {
        set(data, 'config.filtering.state', { operator: 'in', value: ['picked'] });
        return {
            ...data,
        };
    };

    const routes = {
        fulfillmentShipMain: {
            path: app.fulfillmentShipTabs.getItem('main').link,
            title: app.fulfillmentShipTabs.getItem('main').title,
            permission: app.fulfillmentShipTabs.getItem('main').permission,
            breadcrumbConfig: { title: `${SHIP_TITLE_PREFIX}`, isTab: true, clearPath: true },
            props: () => ({
                domain: SHIP_DOMAIN_NAME,
                detailsPageRoute: SHIP_DETAILS_ROUTE,
                pickPageRoute: SHIP_PICKING_ROUTE,
                packPageRoute: SHIP_PACKING_ROUTE,
                printLogsPageRoute: SHIP_PRINT_LOGS_ROUTE,
                permissions: {
                    withFulfillmentDetailsLink: hasAnyPermissions('fulfillments.show'),
                },
                currentUserId: UserModel.getUserId(),
                workflow: SHIP_WORKFLOW_NAME,
                cardsConfig : [
                    {
                        id: 'pick',
                        label: 'Single picks',
                        link: SHIP_PICKING_ROUTE,
                        color: 'primary',
                        config: {
                            countable: true,
                            countGraphqlFilterPart: 'current_state: {operator: in, value: ["pick","pick_in_progress"]}',
                        },
                    },
                    {
                        id: 'groupPick',
                        label: 'Group picks',
                        link: SHIP_GROUP_PICKING_ROUTE,
                        color: 'secondary',
                        config: {
                            countable: true,
                            countGraphqlFilterPart:
                                'type: { operator: like, value: "%manual%" }, state: { operator: neq, value: "picked" }',
                            gridField: 'groupGrid',
                        },
                    },
                    {
                        id: 'waves',
                        label: 'Waves',
                        link: SHIP_GROUP_PICKING_ROUTE,
                        color: 'warning',
                        config: {
                            countable: true,
                            countGraphqlFilterPart:
                                'type: { operator: like, value: "%wave%" }, state: { operator: neq, value: "picked" }',
                            gridField: 'groupGrid',
                        },
                    },
                ]
            }),
            loader: () => import('@skunexus/fulfillment-dashboard'),
            render: (Component) =>
                function (props) {
                    const [{ fulfillmentSetting: { isBatchingEnabled = false } = {} }] = useConfigContext();

                    const shipMainCustomGridSections = (config) => {
                        const newConfig = cloneDeep(config);
                        newConfig.sections = {
                            ...newConfig.sections,
                            batched: {
                                code: 'batched',
                                title: 'Batching',
                                path: SHIP_BATCHING_ROUTE,
                                status: ['batched', 'pick', 'pick_in_progress', 'printable'],
                                order: 10,
                            },
                            printed: {
                                code: 'printed',
                                title: 'Printed',
                                path: SHIP_PRINTED_ROUTE,
                                order: 20,
                            },
                        };
                        newConfig.sections = omit(newConfig.sections, ['pick', 'pack']);
                        return newConfig;
                    };

                    return (
                        <MainTabs
                            filterProps={{
                                assignUserFilterConfig: {
                                    isVisible: false,
                                },
                                cartFilterConfig: {
                                    isVisible: false,
                                },
                            }}
                            {...props}
                            name="fulfillmentShipMain"
                        >
                            <Component
                                {...props}
                                customGridSections={isBatchingEnabled ? shipMainCustomGridSections : undefined}
                            />
                        </MainTabs>
                    );
                },
        },
        fulfillmentShipPrintLogs: {
            path: app.fulfillmentShipTabs.getItem('printLogs').link,
            title: app.fulfillmentShipTabs.getItem('printLogs').title,
            permission: app.fulfillmentShipTabs.getItem('printLogs').permission,
            breadcrumbConfig: {
                title: app.fulfillmentShipTabs.getItem('printLogs').label,
                isReplaceble: true,
                isTab: true,
            },
            props: () => ({
                shipmentDetailsPageRoute: SHIPMENT_DETAILS_ROUTE,
                permissions: {
                    canView: hasAnyPermissions('fulfillments.show'),
                    withExport: true,
                    withFulfillmentDetailsLink: hasAnyPermissions('fulfillments.show'),
                },
            }),
            loader: () => import('@skunexus/fulfillment-print-logs'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs
                            filterProps={{
                                assignUserFilterConfig: {
                                    isVisible: false,
                                },
                                cartFilterConfig: {
                                    isVisible: false,
                                },
                            }}
                            {...props}
                            name="fulfillmentShipPrintLogs"
                        >
                            <Component {...props} />
                        </MainTabs>
                    );
                },
        },
        fulfillmentShipDispatched: {
            path: app.fulfillmentShipTabs.getItem('dispatched').link,
            title: app.fulfillmentShipTabs.getItem('dispatched').title,
            permission: app.fulfillmentShipTabs.getItem('dispatched').permission,
            breadcrumbConfig: {
                title: app.fulfillmentShipTabs.getItem('dispatched').label,
                isReplaceble: true,
                isTab: true,
            },
            props: () => ({
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                detailsPageRoute: SHIPMENT_DETAILS_ROUTE,
                packingPageRoute: SHIP_PACKING_ROUTE,
                printLogsPageRoute: SHIP_PRINT_LOGS_ROUTE,
                permissions: {
                    canView: hasAnyPermissions('fulfillments.show'),
                    canChangeState: true,
                    withExport: true,
                    withOrderDetailsLink: hasAnyPermissions('orders.show'),
                    withFulfillmentDetailsLink: hasAnyPermissions('fulfillments.show'),
                },
            }),
            loader: () => import('@skunexus/fulfillment-dispatched'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs
                            filterProps={{
                                cartFilterConfig: {
                                    isVisible: false,
                                },
                            }}
                            {...props}
                            name="fulfillmentShipDispatched"
                        >
                            <Component {...props} />
                        </MainTabs>
                    );
                },
        },
        fulfillmentShipFulfilled: {
            path: app.fulfillmentShipTabs.getItem('finalized').link,
            title: app.fulfillmentShipTabs.getItem('finalized').title,
            permission: app.fulfillmentShipTabs.getItem('finalized').permission,
            breadcrumbConfig: {
                title: app.fulfillmentShipTabs.getItem('finalized').label,
                isReplaceble: true,
                isTab: true,
            },
            props: () => ({
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                detailsPageRoute: SHIP_DETAILS_ROUTE,
                permissions: {
                    canView: hasAnyPermissions('fulfillments.show'),
                    withExport: true,
                    withOrderDetailsLink: hasAnyPermissions('orders.show'),
                    withFulfillmentDetailsLink: hasAnyPermissions('fulfillments.show'),
                },
            }),
            loader: () => import('@skunexus/fulfillment-fulfilled'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs
                            filterProps={{
                                cartFilterConfig: {
                                    isVisible: false,
                                },
                            }}
                            {...props}
                            name="fulfillmentShipFulfilled"
                        >
                            <Component {...props} />
                        </MainTabs>
                    );
                },
        },
        fulfillmentAll: {
            path: app.fulfillmentShipTabs.getItem('all').link,
            title: app.fulfillmentShipTabs.getItem('all').title,
            permission: app.fulfillmentShipTabs.getItem('all').permission,
            breadcrumbConfig: { title: app.fulfillmentShipTabs.getItem('all').label, isReplaceble: true, isTab: true },
            props: () => ({
                shipmentDetailsPageRoute: SHIPMENT_DETAILS_ROUTE,
                orderDetailsRoute: ORDERS_DETAILS_ROUTE,
                permissions: {
                    canView: hasAnyPermissions('fulfillments.show'),
                    canReship: hasAnyPermissions('fulfillments.reship'),
                    canRefund: false,
                    withExport: true,
                    withOrderDetailsLink: hasAnyPermissions('orders.show'),
                    withFulfillmentDetailsLink: hasAnyPermissions('fulfillments.show'),
                },
            }),
            loader: () => import('@skunexus/fulfillment-all'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs
                            filterProps={{
                                assignUserFilterConfig: {
                                    isVisible: false,
                                },
                                cartFilterConfig: {
                                    isVisible: false,
                                },
                            }}
                            {...props}
                            name="fulfillmentAll"
                        >
                            <Component {...props} />
                        </MainTabs>
                    );
                },
        },
        fulfillmentShipCancelled: {
            path: app.fulfillmentShipTabs.getItem('cancelled').link,
            title: app.fulfillmentShipTabs.getItem('cancelled').title,
            permission: app.fulfillmentShipTabs.getItem('cancelled').permission,
            breadcrumbConfig: {
                title: app.fulfillmentShipTabs.getItem('cancelled').label,
                isReplaceble: true,
                isTab: true,
            },
            props: () => ({
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                detailsPageRoute: SHIP_DETAILS_ROUTE,
                permissions: {
                    canView: hasAnyPermissions('fulfillments.show'),
                    withExport: true,
                    withOrderDetailsLink: hasAnyPermissions('orders.show'),
                    withFulfillmentDetailsLink: hasAnyPermissions('fulfillments.show'),
                },
            }),
            loader: () => import('@skunexus/fulfillment-cancelled'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs {...props} name="fulfillmentShipCancelled">
                            <Component {...props} />
                        </MainTabs>
                    );
                },
        },
        fulfillmentHospital: {
            path: app.fulfillmentShipTabs.getItem('hospital').link,
            title: app.fulfillmentShipTabs.getItem('hospital').title,
            permission: app.fulfillmentShipTabs.getItem('hospital').permission,
            breadcrumbConfig: {
                title: app.fulfillmentShipTabs.getItem('hospital').label,
                isReplaceble: true,
                isTab: true,
            },
            props: () => ({
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                detailsPageRoute: SHIP_DETAILS_ROUTE,
                cancelPageRoute: SHIP_CANCEL_ROUTE,
                permissions: {
                    canResolve: hasAnyPermissions('fulfillments.hospital'),
                    withExport: true,
                    withOrderDetailsLink: hasAnyPermissions('orders.show'),
                    withFulfillmentDetailsLink: hasAnyPermissions('fulfillments.show'),
                },
            }),
            loader: () => import('@skunexus/fulfillment-hospital'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs {...props} name="fulfillmentHospital">
                            <Component {...props} />
                        </MainTabs>
                    );
                },
        },
        fulfillmentShipDetails: {
            path: `${SHIP_DETAILS_ROUTE}/:id`,
            title: `${SHIP_TITLE_PREFIX} > Details`,
            breadcrumbConfig: { title: EMPTY_TITLE_CRUMB, isReplaceble: true },
            props: () => ({
                routes: {
                    orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                    pickingPageRoute: SHIP_PICKING_ROUTE,
                    packingPageRoute: SHIP_PACKING_ROUTE,
                    packPageRoute: SHIP_PACK_ROUTE,
                    productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                    // detailsPageRoute: SHIP_DETAILS_ROUTE,
                    shipmentDetailsPageRoute: SHIPMENT_DETAILS_ROUTE,
                    shipmentPrintPageRoute: SHIPMENT_PRINT_ROUTE,
                    itemsPrintPageRoute: SHIP_PRINT_ITEMS_ROUTE,
                },
                currentUserId: UserModel.getUserId(),
                permissions: {
                    canPick: true,
                    canPack: true,
                    canCancel: true,
                    canPrintShipment: hasAnyPermissions('fulfillments.print'),
                    canPrintItems: hasAnyPermissions('fulfillments.print'),
                    canAssignUser: hasAnyPermissions('fulfillments.assign user'),
                    canUnassignUser: hasAnyPermissions('fulfillments.unassign user'),
                    withOrderDetailsLink: hasAnyPermissions('orders.show'),
                    withProductDetailsLink: hasAnyPermissions('products.show'),
                },
            }),
            permission: ['fulfillments.show'],
            loader: () => import('@skunexus/fulfillment-details'),
            render: (Component) =>
                function (props) {
                    const [{ fulfillmentSetting: { isBatchingEnabled = false } = {} }] = useConfigContext();
                    return <Component {...props} batchEnabled={isBatchingEnabled} />;
                },
        },
        fulfillmentShipPrintItems: {
            path: `${SHIP_PRINT_ITEMS_ROUTE}/:id`,
            title: `${SHIP_TITLE_PREFIX} > Print Items`,
            permission: ['fulfillments.show'],
            breadcrumbConfig: { title: EMPTY_TITLE_CRUMB, isReplaceble: true },
            props: () => ({}),
            loader: () => import('@skunexus/fulfillment-print-items'),
        },
        // fulfillmentShipDetailsPrint: {
        //     path: `${SHIP_DETAILS_ROUTE}/print/:id`,
        //     title: `${SHIP_TITLE_PREFIX} > Details > Print Preview`,
        //     breadcrumbConfig: { title: 'Print Preview', isReplaceble: true },
        //     props: () => ({
        //         orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
        //         pickingPageRoute: SHIP_PICKING_ROUTE,
        //         packingPageRoute: SHIP_PACKING_ROUTE,
        //         productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
        //         detailsPageRoute: SHIP_DETAILS_ROUTE,
        //         shipmentDetailsPageRoute: SHIPMENT_DETAILS_ROUTE,
        //         currentUserId: UserModel.getUserId(),
        //     }),
        //     layout: PrintLayout,
        //     permission: ['fulfillments.show'],
        //     loader: () => import('@skunexus/fulfillment-details'),
        // },
        fulfillmentShipPick: {
            path: app.fulfillmentShipTabs.getItem('pick').link,
            title: app.fulfillmentShipTabs.getItem('pick').title,
            permission: app.fulfillmentShipTabs.getItem('pick').permission,
            breadcrumbConfig: { title: app.fulfillmentShipTabs.getItem('pick').label, isReplaceble: true, isTab: true },
            props: () => ({
                routes: {
                    orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                    detailsPageRoute: SHIP_PICKING_ROUTE,
                    groupPageRoute: SHIP_GROUP_PICKING_ROUTE,
                },
                currentUserId: UserModel.getUserId(),
                permissions: {
                    canAssignUser: hasAnyPermissions('fulfillments.assign user'),
                    canUnassignUser: hasAnyPermissions('fulfillments.unassign user'),
                    canAssignCart: hasAnyPermissions('fulfillments.assign cart'),
                    canView: hasAnyPermissions('fulfillments.show'),
                    canGroup: hasAnyPermissions('fulfillments - groups.create'),
                    withOrderDetailsLink: hasAnyPermissions('orders.show'),
                    withFulfillmentDetailsLink: hasAnyPermissions('fulfillments.show'),
                },
            }),
            loader: () => import('@skunexus/fulfillment-pick'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs {...props} name="fulfillmentShipPick">
                            <Component {...props} />
                        </MainTabs>
                    );
                },
            batchingMode: false,
        },
        fulfillmentShipPicking: {
            path: `${SHIP_PICKING_ROUTE}/:id`,
            title: `${SHIP_TITLE_PREFIX} > Picking`,
            permission: ['fulfillments.picking'],
            breadcrumbConfig: { title: 'Picking', isReplaceble: true },
            props: () => ({
                routes: {
                    orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                    pickingPageRoute: SHIP_PICKING_ROUTE,
                    packingPageRoute: SHIP_PACKING_ROUTE,
                    pickPageRoute: SHIP_PICK_ROUTE,
                    cancelPageRoute: SHIP_CANCEL_ROUTE,
                    productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                    detailsPageRoute: SHIP_DETAILS_ROUTE,
                    shipmentDetailsPageRoute: SHIPMENT_DETAILS_ROUTE,
                    itemsPrintPageRoute: SHIP_PRINT_ITEMS_ROUTE,
                },
                permissions: {
                    canCancel: true,
                    canAssignUser: hasAnyPermissions('fulfillments.assign user'),
                    canUnassignUser: hasAnyPermissions('fulfillments.unassign user'),
                    canPrintItems: hasAnyPermissions('fulfillments.print'),
                    withProductDetailsLink: hasAnyPermissions('products.show'),
                    withOrderDetailsLink: hasAnyPermissions('orders.show'),
                },
                currentUserId: UserModel.getUserId(),
            }),
            loader: () => import('@skunexus/fulfillment-picking'),
            batchingMode: false,
            render: (Component) =>
                function (props) {
                    const [{ setting }] = useConfigContext();
                    const { isKittingEnabled, isForceEntireKittingEnabled } = setting;

                    return <Component {...props} {...{ isKittingEnabled, isForceEntireKittingEnabled }} />;
                },
        },
        fulfillmentCancel: {
            path: `${SHIP_CANCEL_ROUTE}/:id`,
            title: `${SHIP_TITLE_PREFIX} > Cancel`,
            permission: ['fulfillments.index'],
            breadcrumbConfig: { title: 'Cancel', isReplaceble: true },
            props: () => ({
                domain: SHIP_DOMAIN_NAME,
                packPageRoute: SHIP_PACK_ROUTE,
                packingPageRoute: SHIP_PACKING_ROUTE,
                pickPageRoute: SHIP_PICK_ROUTE,
                pickingPageRoute: SHIP_PICKING_ROUTE,
                dashboardPageRoute: SHIP_DASHBOARD_ROUTE,
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                showItemsImages: true,
                permissions: {
                    withProductDetailsLink: hasAnyPermissions('products.show'),
                },
            }),
            loader: () => import('@skunexus/fulfillment-cancel'),
            render: (Component) =>
                function (props) {
                    const [{ setting, fulfillmentSetting }] = useConfigContext();
                    const { isKittingEnabled = false, isForceEntireKittingEnabled = false } = setting;
                    const { isBatchingEnabled = false } = fulfillmentSetting;

                    return (
                        <Component
                            {...props}
                            {...{ isKittingEnabled, isForceEntireKittingEnabled }}
                            {...(isBatchingEnabled && {
                                packPageRoute: SHIP_DASHBOARD_ROUTE,
                                packingPageRoute: SHIP_DETAILS_ROUTE,
                                pickPageRoute: SHIP_DASHBOARD_ROUTE,
                                pickingPageRoute: SHIP_DETAILS_ROUTE,
                            })}
                        />
                    );
                },
        },
        fulfillmentShipGroupPick: {
            path: app.fulfillmentShipTabs.getItem('groupPick').link,
            title: app.fulfillmentShipTabs.getItem('groupPick').title,
            permission: app.fulfillmentShipTabs.getItem('groupPick').permission,
            breadcrumbConfig: {
                title: app.fulfillmentShipTabs.getItem('groupPick').label,
                isReplaceble: true,
                isTab: true,
            },
            props: () => ({
                routes: {
                    detailsPageRoute: SHIP_GROUP_PICK_DETAILS_ROUTE,
                },
                currentUserId: UserModel.getUserId(),
                permissions: {
                    canAssignUser: hasAnyPermissions('fulfillments - groups.assign user'),
                    canUnassignUser: hasAnyPermissions('fulfillments - groups.unassign user'),
                    canAssignCart: hasAnyPermissions('fulfillments - groups.assign cart'),
                    canView: hasAnyPermissions('fulfillments.show'),
                    withGroupPickDetailsLink: hasAnyPermissions('fulfillments.show'),
                    withExport: true,
                },
            }),
            loader: () => import('@skunexus/fulfillment-group-pick'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs {...props} name="fulfillmentShipGroupPick">
                            <Component {...props} />
                        </MainTabs>
                    );
                },
            batchingMode: false,
        },
        fulfillmentShipGroupPicking: {
            path: `${SHIP_GROUP_PICKING_ROUTE}/:id`,
            title: `${SHIP_TITLE_PREFIX} > Group Picking`,
            permission: ['fulfillments.picking'],
            breadcrumbConfig: { title: 'Group Picking', isReplaceble: true },
            props: () => ({
                routes: {
                    packPageRoute: SHIP_PACK_ROUTE,
                    groupPickPageRoute: SHIP_GROUP_PICK_ROUTE,
                    productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                    printItemsPageRoute: `${SHIP_GROUP_PICK_DETAILS_ROUTE}/print`,
                    itemsPrintPageRoute: SHIP_GROUP_PRINT_ITEMS_ROUTE,
                },
                permissions: {
                    canAssignUser: true,
                    canUnassignUser: true,
                    canPrintItems: hasAnyPermissions('fulfillments.print'),
                    withProductDetailsLink: hasAnyPermissions('products.show'),
                },
            }),
            loader: () => import('@skunexus/fulfillment-group-picking'),
            batchingMode: false,
        },
        fulfillmentShipGroupPickDetails: {
            path: `${SHIP_GROUP_PICK_DETAILS_ROUTE}/:id`,
            title: `${SHIP_TITLE_PREFIX} > Group Pick Details`,
            permission: ['fulfillments.picking'],
            breadcrumbConfig: { title: EMPTY_TITLE_CRUMB, isReplaceble: true },
            props: () => ({
                routes: {
                    fulfillmentDetailsPageRoute: SHIP_DETAILS_ROUTE,
                    wavePageRoute: SHIP_WAVES_ROUTE,
                    groupPickPageRoute: SHIP_GROUP_PICK_ROUTE,
                    pickingPageRoute: SHIP_GROUP_PICKING_ROUTE,
                    productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                    itemsPrintPageRoute: SHIP_GROUP_PRINT_ITEMS_ROUTE,
                },
                currentUserId: UserModel.getUserId(),
                permissions: {
                    canPick: true,
                    canPrintItems: hasAnyPermissions('fulfillments.print'),
                    canAssignUser: hasAnyPermissions('fulfillments - groups.assign user'),
                    canUnassignUser: hasAnyPermissions('fulfillments - groups.unassign user'),
                    canUngroup: true,
                    withProductDetailsLink: hasAnyPermissions('products.show'),
                    withFulfillmentDetailsLink: hasAnyPermissions('fulfillments.show'),
                },
            }),
            loader: () => import('@skunexus/fulfillment-group-pick-details'),
        },
        fulfillmentShipGroupPrintItems: {
            path: `${SHIP_GROUP_PRINT_ITEMS_ROUTE}/:id`,
            title: `${SHIP_TITLE_PREFIX} > Group Print Items`,
            permission: ['fulfillments.show'],
            breadcrumbConfig: { title: EMPTY_TITLE_CRUMB, isReplaceble: true },
            props: () => ({
                detailsField: 'groupDetails',
            }),
            loader: () => import('@skunexus/fulfillment-group-print-items'),
        },
        fulfillmentShipBatching: {
            path: app.fulfillmentShipTabs.getItem('batching').link,
            title: app.fulfillmentShipTabs.getItem('batching').title,
            permission: app.fulfillmentShipTabs.getItem('batching').permission,
            breadcrumbConfig: { title: app.fulfillmentShipTabs.getItem('batching').label, isReplaceble: true },
            props: () => ({
                printRoute: SHIP_BATCH_PRINT_ROUTE,
                fulfillmentDetailsRoute: SHIP_DETAILS_ROUTE,
                orderDetailsRoute: ORDERS_DETAILS_ROUTE,
                permissions: {
                    canPrint: true,
                    canBatch: true,
                    withOrderDetailsLink: hasAnyPermissions('orders.show'),
                    withFulfillmentDetailsLink: hasAnyPermissions('fulfillments.show'),
                },
            }),
            loader: () => import('@skunexus/fulfillment-batching'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs
                            filterProps={{
                                assignUserFilterConfig: {
                                    isVisible: false,
                                },
                                cartFilterConfig: {
                                    isVisible: false,
                                },
                            }}
                            {...props}
                            name="fulfillmentShipBatching"
                        >
                            <Component {...props} />
                        </MainTabs>
                    );
                },
            batchingMode: true,
        },
        fulfillmentShipBatchingPrint: {
            path: '/fulfillment-ship/batch-print',
            title: `${SHIP_TITLE_PREFIX} > Batching`,
            permission: app.fulfillmentShipTabs.getItem('batching').permission,
            breadcrumbConfig: { title: `Batching`, isReplaceble: true },
            props: () => ({
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                fulfillmentDetailsPageRoute: SHIP_DETAILS_ROUTE,
                returnRoute: SHIP_BATCHING_ROUTE,
            }),
            loader: () => import('@skunexus/fulfillment-batching-print'),
            batchingMode: true,
        },
        fulfillmentShipPrinted: {
            path: app.fulfillmentShipTabs.getItem('printed').link,
            title: app.fulfillmentShipTabs.getItem('printed').title,
            permission: app.fulfillmentShipTabs.getItem('printed').permission,
            breadcrumbConfig: { title: app.fulfillmentShipTabs.getItem('printed').label, isReplaceble: true },
            props: () => ({
                shipmentDetailsPageRoute: SHIPMENT_DETAILS_ROUTE,
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                permissions: {
                    canView: hasAnyPermissions('fulfillments.show'),
                    withOrderDetailsLink: hasAnyPermissions('orders.show'),
                    withFulfillmentDetailsLink: hasAnyPermissions('fulfillments.show'),
                },
            }),
            loader: () => import('@skunexus/fulfillment-printed'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs
                            filterProps={{
                                assignUserFilterConfig: {
                                    isVisible: false,
                                },
                                cartFilterConfig: {
                                    isVisible: false,
                                },
                            }}
                            {...props}
                            name="fulfillmentShipPrinted"
                        >
                            <Component {...props} />
                        </MainTabs>
                    );
                },

            batchingMode: true,
        },
        fulfillmentShipPack: {
            path: app.fulfillmentShipTabs.getItem('pack').link,
            title: app.fulfillmentShipTabs.getItem('pack').title,
            permission: app.fulfillmentShipTabs.getItem('pack').permission,
            breadcrumbConfig: { title: app.fulfillmentShipTabs.getItem('pack').label, isReplaceble: true, isTab: true },
            props: () => ({
                orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                detailsPageRoute: SHIP_PACKING_ROUTE,
                packingPageRoute: SHIP_PACKING_ROUTE,
                printLogsPageRoute: SHIP_PRINT_LOGS_ROUTE,
                currentUserId: UserModel.getUserId(),
                permissions: {
                    canScan: true,
                    canView: true,
                    canChangeState: true,
                    withExport: true,
                    withOrderDetailsLink: hasAnyPermissions('orders.show'),
                    withFulfillmentDetailsLink: true,
                },
            }),
            loader: () => import('@skunexus/fulfillment-pack'),
            render: (Component) =>
                function (props) {
                    const { history } = props;
                    return (
                        <MainTabs {...props} name="fulfillmentShipPack">
                            <Component
                                {...props}
                                onFailedToPurchaseLabel={(fulfillmentId, barcode) =>
                                    history.push(`/fulfillment-ship/packing/${fulfillmentId}?barcode=${barcode}`)
                                }
                            />
                        </MainTabs>
                    );
                },
            batchingMode: false,
        },
        fulfillmentShipPacking: {
            path: `${SHIP_PACKING_ROUTE}/:id`,
            title: `${SHIP_TITLE_PREFIX} > Packing`,
            permission: ['fulfillments.packing'],
            breadcrumbConfig: { title: 'Packing', isReplaceble: true },
            props: () => ({
                routes: {
                    productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                    orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                    pickingPageRoute: SHIP_PICKING_ROUTE,
                    packingPageRoute: SHIP_PACKING_ROUTE,
                    packPageRoute: SHIP_PACK_ROUTE,
                    detailsPageRoute: SHIP_DETAILS_ROUTE,
                    shipmentDetailsPageRoute: SHIPMENT_DETAILS_ROUTE,
                    shipmentPrintPageRoute: SHIPMENT_PRINT_ROUTE,
                },
                currentUserId: UserModel.getUserId(),
                showItemsImages: true,
                itemsHideUntilParcelSelected: false,
                withManualQuantity: false,
                withScanQtySelect: false,
                permissions: {
                    canAssignUser: true,
                    canUnassignUser: true,
                    canCancel: true,
                    withProductDetailsLink: hasAnyPermissions('products.show'),
                    withOrderDetailsLink: hasAnyPermissions('orders.show'),
                },
            }),
            loader: () => import('@skunexus/fulfillment-packing'),
        },
        fulfillmentWaves: {
            path: app.fulfillmentWavesTabs.getItem('waves').link,
            title: app.fulfillmentWavesTabs.getItem('waves').title,
            permission: app.fulfillmentWavesTabs.getItem('waves').permission,
            breadcrumbConfig: {
                title: app.fulfillmentShipTabs.getItem('waves').label,
                isReplaceble: true,
                isTab: true,
            },
            props: () => ({
                routes: {
                    detailsPageRoute: SHIP_GROUP_PICK_DETAILS_ROUTE,
                },
                customGridConfig: customWaveGridConfig,
                permissions: {
                    canAssignUser: hasAnyPermissions('fulfillments - groups.assign user'),
                    canUnassignUser: hasAnyPermissions('fulfillments - groups.unassign user'),
                    canAssignCart: hasAnyPermissions('fulfillments - groups.assign cart'),
                    canReleaseWave: hasAnyPermissions('fulfillments - waves.release'),
                    canView: hasAnyPermissions('fulfillments.show'),
                    withGroupPickDetailsLink: hasAnyPermissions('fulfillments.show'),
                    withExport: true,
                },
            }),
            loader: () => import('@skunexus/fulfillment-waves'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs {...props} name="fulfillmentWaves">
                            <Component {...props} />
                        </MainTabs>
                    );
                },
            batchingMode: false,
        },
        fulfillmentWavesHistory: {
            path: app.fulfillmentWavesTabs.getItem('wavesHistory').link,
            title: app.fulfillmentWavesTabs.getItem('wavesHistory').title,
            permission: app.fulfillmentWavesTabs.getItem('wavesHistory').permission,
            breadcrumbConfig: {
                title: app.fulfillmentWavesTabs.getItem('wavesHistory').label,
                isReplaceble: true,
                isTab: true,
            },
            props: () => ({
                routes: {
                    detailsPageRoute: SHIP_GROUP_PICK_DETAILS_ROUTE,
                },
                gridConfig: {
                    id: 'fulfillment-wave-history-grid',
                    domain: SHIP_DOMAIN_NAME,
                    workflow: SHIP_WORKFLOW_NAME,
                },
                releaseWaveEnabled: false,
                customGridData: customWaveHistoryGridData,
                customGridConfig: customWaveHistoryGridConfig,
                permissions: {
                    canAssignUser: hasAnyPermissions('fulfillments - groups.assign user'),
                    canUnassignUser: hasAnyPermissions('fulfillments - groups.unassign user'),
                    canAssignCart: hasAnyPermissions('fulfillments - groups.assign cart'),
                    canReleaseWave: false,
                    canView: hasAnyPermissions('fulfillments.show'),
                    withGroupPickDetailsLink: hasAnyPermissions('fulfillments.show'),
                    withExport: true,
                },
            }),
            loader: () => import('@skunexus/fulfillment-waves'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs {...props} name="fulfillmentWavesHistory">
                            <Component {...props} />
                        </MainTabs>
                    );
                },
            batchingMode: false,
        },
        fulfillmentWavesSettings: {
            path: app.settingsFulfillmentTabs.getItem('waves').link,
            title: app.settingsFulfillmentTabs.getItem('waves').label,
            permission: app.settingsFulfillmentTabs.getItem('waves').permission,
            breadcrumbConfig: {
                title: `Fulfillment - ${app.settingsFulfillmentTabs.getItem('waves').label}`,
                clearPath: true,
            },
            props: () => ({
                permissions: {
                    canAdd: hasAnyPermissions('fulfillments - waves.create'),
                    canEdit: hasAnyPermissions('fulfillments - waves.update'),
                    canDelete: hasAnyPermissions('fulfillments - waves.delete'),
                    canActivateOrDeactivate: hasAnyPermissions('fulfillments - waves.update'),
                },
            }),
            loader: () => import('@skunexus/waves'),
            render: withSettingsTabsWrapper(app),
        },
    };

    return routes;
};
