import React from 'react';

import MainTabs from './components/ReceiveInventoryMainTabsLayout';
import {
    TITLE_PREFIX,
    PUT_AWAY_TITLE_PREFIX,
    RI_INDEX_ROUTE,
    RI_JUST_ADDING_ROUTE,
    RI_ITEMS_INDEX_ROUTE,
    RI_PUT_AWAY_INDEX_ROUTE,
    RI_PUT_AWAY_PATH,
    RI_PUT_AWAY_ROUTE,
    RI_PUT_AWAY_FINALIZED_INDEX_ROUTE,
    RI_PUT_AWAY_DETAILS_PATH,
    RI_PUT_AWAY_DETAILS_ROUTE,
    RI_PUT_AWAY_CANCELLED_INDEX_ROUTE,
} from './consts';

import { PRODUCTS_DETAILS_ROUTE } from '../products/consts';
import { hasAnyPermissions } from '../../../utils/helpers';

export default () => {
    const routers = {
        receiveInventoryJai: {
            path: RI_JUST_ADDING_ROUTE,
            title: `${TITLE_PREFIX} - Just Adding Inventory`,
            permission: ['inventory - stocks.create', 'inventory - stocks.adjust'],
            breadcrumbConfig: { title: `Just Adding Inventory`, isReplaceble: true },
            props: () => ({
                routes: {
                    indexPageRoute: RI_INDEX_ROUTE,
                    productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                },
                mode: {},
            }),
            loader: () => import('@skunexus/receive-inventory-just-adding'),
        },
        receiveInventoryMain: {
            path: RI_INDEX_ROUTE,
            title: TITLE_PREFIX,
            permission: ['purchase orders - stocks.receive'],
            breadcrumbConfig: { title: `${TITLE_PREFIX}`, isTab: true, clearPath: true },
            props: () => ({
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                jaiRoute: RI_JUST_ADDING_ROUTE,
                itemsReceiveRoute: RI_ITEMS_INDEX_ROUTE,
                permissions: {
                    withExistingPO: true,
                    withCustomerReturn: true,
                    withJustAddingInventory: hasAnyPermissions([
                        'inventory - stocks.create',
                        'inventory - stocks.adjust',
                    ]),
                },
            }),
            loader: () => import('@skunexus/receive-inventory'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs
                            filterProps={{
                                assignUserFilterConfig: {
                                    isVisible: false,
                                },
                                warehouseFilterConfig: {
                                    isVisible: false,
                                },
                                cartFilterConfig: {
                                    isVisible: false,
                                },
                            }}
                            {...props}
                            name="receiveInventoryMain"
                        >
                            <Component {...props} />
                        </MainTabs>
                    );
                },
        },
        receiveInventoryItems: {
            path: RI_ITEMS_INDEX_ROUTE,
            title: 'Items Received',
            permission: ['receive inventory.put away'],
            breadcrumbConfig: { title: 'Items Received', isReplaceble: true, isTab: true },
            props: () => ({
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                putAwayPageRoute: RI_PUT_AWAY_INDEX_ROUTE,
            }),
            loader: () => import('@skunexus/receive-inventory-items-received'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs
                            filterProps={{
                                assignUserFilterConfig: {
                                    isVisible: false,
                                },
                                warehouseFilterConfig: {
                                    isVisible: false,
                                },
                                cartFilterConfig: {
                                    isVisible: false,
                                },
                            }}
                            {...props}
                            name="receiveInventoryItems"
                        >
                            <Component {...props} />
                        </MainTabs>
                    );
                },
        },
        receiveInventoryPutAway: {
            path: RI_PUT_AWAY_INDEX_ROUTE,
            title: `Items to ${PUT_AWAY_TITLE_PREFIX}`,
            permission: ['receive inventory.put away'],
            breadcrumbConfig: { title: 'Items To Put Away', isReplaceble: true, isTab: true },
            props: () => ({
                putAwayMainRoute: RI_PUT_AWAY_ROUTE,
                permissions: {
                    withExport: true,
                    canBeginPutAway: true,
                    canCancel: hasAnyPermissions('fulfillments.put away rollback and remove'),
                    withPutAwayLink: true,
                },
            }),
            loader: () => import('@skunexus/put-away'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs {...props} name="receiveInventoryPutAway">
                            <Component {...props} />
                        </MainTabs>
                    );
                },
        },
        receiveInventoryPutAwayMain: {
            path: RI_PUT_AWAY_PATH,
            title: PUT_AWAY_TITLE_PREFIX,
            permission: ['receive inventory.put away'],
            breadcrumbConfig: { title: 'Put Away', isReplaceble: true },
            props: () => ({
                routes: {
                    putAwayRoute: RI_PUT_AWAY_INDEX_ROUTE,
                    putAwayFinalRoute: RI_PUT_AWAY_FINALIZED_INDEX_ROUTE,
                    putAwayFinalDetailsRoute: RI_PUT_AWAY_DETAILS_ROUTE,
                    productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                },
                permissions: {
                    canAssignUser: true,
                    withProductLink: hasAnyPermissions('products.show'),
                },
            }),
            loader: () => import('@skunexus/put-away-main'),
        },
        receiveInventoryPutAwayFinal: {
            path: RI_PUT_AWAY_FINALIZED_INDEX_ROUTE,
            title: `Finalized ${PUT_AWAY_TITLE_PREFIX}`,
            permission: ['receive inventory.put away'],
            breadcrumbConfig: { title: 'Finalized', isReplaceble: true, isTab: true },
            props: () => ({
                detailsPageRoute: RI_PUT_AWAY_DETAILS_ROUTE,
                permissions: {
                    withExport: true,
                    canView: true,
                    withPutAwayLink: true,
                },
            }),
            loader: () => import('@skunexus/put-away-final'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs
                            filterProps={{
                                cartFilterConfig: {
                                    isVisible: false,
                                },
                            }}
                            {...props}
                            name="receiveInventoryPutAwayFinal"
                        >
                            <Component {...props} />
                        </MainTabs>
                    );
                },
        },
        receiveInventoryPutAwayCancelled: {
            path: RI_PUT_AWAY_CANCELLED_INDEX_ROUTE,
            title: `Cancelled ${PUT_AWAY_TITLE_PREFIX}`,
            permission: ['receive inventory.put away'],
            breadcrumbConfig: { title: 'Cancelled', isReplaceble: true, isTab: true },
            props: () => ({
                detailsPageRoute: RI_PUT_AWAY_DETAILS_ROUTE,
                exportFileName: 'put-away-cancelled',
                permissions: {
                    withExport: true,
                    canView: true,
                    withPutAwayLink: true,
                },
            }),
            loader: () => import('@skunexus/put-away-cancelled'),
            render: (Component) =>
                function (props) {
                    return (
                        <MainTabs
                            filterProps={{
                                cartFilterConfig: {
                                    isVisible: false,
                                },
                            }}
                            {...props}
                            name="receiveInventoryPutAwayCancelled"
                        >
                            <Component {...props} state="cancelled" />
                        </MainTabs>
                    );
                },
        },
        receiveInventoryPutAwayFinalDetails: {
            path: RI_PUT_AWAY_DETAILS_PATH,
            title: `${PUT_AWAY_TITLE_PREFIX} Details`,
            permission: ['receive inventory.put away'],
            breadcrumbConfig: { title: 'Put Away Details', isReplaceble: true },
            props: () => ({
                productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                permissions: {
                    withProductLink: hasAnyPermissions('products.show'),
                },
            }),
            loader: () => import('@skunexus/put-away-final-details'),
        },
    };

    return routers;
};
