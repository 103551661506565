import { findIndex } from 'lodash';
import { TITLE_PREFIX, ORDERS_INDEX_ROUTE, BACK_ORDERS_ROUTE, BACK_ORDERS_TITLE } from './consts';

export const applyBackOrdersToOrderNavItem = (items = []) => {
    const orderItemIndex = findIndex(items, ['code', 'orders']);

    if (orderItemIndex >= 0) {
        items[orderItemIndex].items = [
            {
                label: TITLE_PREFIX,
                link: ORDERS_INDEX_ROUTE,
                icon: 'orderStatus',
            },
            {
                label: BACK_ORDERS_TITLE,
                link: BACK_ORDERS_ROUTE,
                icon: 'ArrowReturnLeft',
            },
        ];
    }

    return [...items];
};

export default () => ({
    label: `${TITLE_PREFIX}s`,
    link: ORDERS_INDEX_ROUTE,
    icon: 'orders',
    permission: 'orders.index',
    order: 30,
});
